@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* secondary color: #402909;
primary color: #DE5900;
lighter color: #ff6a06; */

body {
    background-color: #191923;
    overflow-x: hidden;
    height: 99%;
    width: 100%;
    color: #ffffff;
    position: fixed;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.5px;
    padding: 15px;
    border: 0px solid #cecece;
}

.Home {
    background-image: url("../../assets/software-developer-gf8ce430c9_1920.jpg");
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px solid #cecece;
    position: relative;
    min-height: 96%;
    width: 100%;
    box-shadow: 1px 1px 5px 0px rgba(33, 33, 33, 0.75);
    -webkit-box-shadow: 1px 1px 5px 0px rgba(33, 33, 33, 0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(33, 33, 33, 0.75);
}

.Bg {
    background-color: #1e1e28;
    opacity: 0.96;
}


.MainBar {
    padding: 0px 20px;
    height: 95vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    border: 0px solid #cecece;
    box-shadow: 0 1px 4px 0 rgba(15, 15, 20, 0.1);
}

.menu-section {
    width: 100%;
    display: none;
}

.menu-section .menu-box {
    box-sizing: border-box;
    background: #2c2c38;
    padding-top: 5px;
    padding-left: 20px;
    height: 60px;
}

.menu-section .menu-box .btn {
    background: #20202a;
    border: 1px solid #424242;
    font-size: 20px;
    color: rgb(140, 140, 142);
    border-radius: 6px;
}

.menu-section .menu-box .btn:focus{
    box-shadow: none;
    outline: none;
    background: #20202a;
    border: 1px solid #424242;
}

.menu-section .menu-box .btn:focus {
    box-shadow: none;
    outline: none;
}

.MainBar::-webkit-scrollbar {
    display: none;
}

.MainBar h4 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.MainBar .hero-section {
    margin-top: 3%;
    padding: 0px 50px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    border-radius: 1px;
    background-color: rgba(218, 218, 218, 0.15);
    backdrop-filter: blur(5px);

    /*background: #595960;*/

}

.MainBar .hero-section .hero-text {
    padding: 50px 0 30px;
}

.MainBar .hero-section .hero-text h3 {
    font-size: 33px;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.MainBar .hero-section .hero-text .code-section {
    margin-bottom: 20px;
}

.MainBar .hero-section .hero-text .hire-button .btn {
    background: #ff6a06;
    border: 1px solid #ff6a06;
    border-radius: 1px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    padding: 13px 40px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.MainBar .hero-section .hero-text .hire-button .btn:focus {
    outline: none;
    box-shadow: none;
}


.MainBar .work-experience {
    margin: 3% 0;
}

.MainBar .work-experience {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
}

.MainBar .work-experience .experience {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-evenly;
}

.MainBar .work-experience .experience h5 {
    font-size: 20px;
    font-weight: 600;
    /* color: #ffc107; */
    color: #ff6a06;
}

.MainBar .work-experience .experience p {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
}

.MainBar .service-section {
    margin-top: 5%;
}

.service-section .row {
    display: flex;
    flex-wrap: wrap;
}

.service-section .col-lg-4 {
    display: flex;
    padding: 8px;
}

.MainBar .service-section .service-box {
    padding: 20px 20px;
    box-sizing: border-box;
    background: #2c2c38;
    color: rgb(157, 157, 157);
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.MainBar .service-section .service-box h6 {
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.MainBar .service-section .service-box p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    padding-bottom: 0;
}


.MainBar .work-section {
    margin-top: 5%;
}

.work-section .row{
    display: flex;
    flex-wrap: wrap;
}

.work-section .col-lg-6{
    display: flex;
    padding: 10px;
}

.MainBar .work-section .work-box {
    padding: 30px 20px 10px;
    box-sizing: border-box;
    background: #2c2c38;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.MainBar .work-section .work-box .work-header {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
}

.MainBar .work-section .work-box .work-header .work-title h5 {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.5px;
}

.MainBar .work-section .work-box .work-header .work-title p {
    font-size: 13px;
    font-style: italic;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(157, 157, 157);
}

.MainBar .work-section .work-box .work-header .work-time h6 {
    font-size: 10px;
    background: #1f1f29;
    border-radius: 20px;
    font-weight: 400;
    color: rgb(140, 140, 142);
    padding: 8px 15px;

}

.MainBar .work-section .work-box .work-description p {
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgb(157, 157, 157);
}


.MainBar .contact-section {
    margin-top: 5%;
}

.MainBar .contact-section .contact-box {
    padding: 30px 20px 20px;
    box-sizing: border-box;
    background: #2c2c38;
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;

}

.MainBar .contact-section .contact-box p {
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.MainBar .contact-section .contact-box .address-two p {
    font-weight: 400;
    text-transform: lowercase;
    text-align: right;
    color: rgb(157, 157, 157);
}

.MainBar .contact-section .contact-box .address-two.main-address p {
    text-transform: capitalize;
}


.MainBar .form-section {
    margin-top: 5%;
    margin-bottom: 5%;
}

.MainBar .form-section .form-box {
    padding: 30px;
    box-sizing: border-box;
    background: #2c2c38;
    margin-bottom: 5%;

}

.MainBar .form-section .form-box .form-group {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
}

.MainBar .form-section .form-box .form-group label {
    background: #20202a;
    padding: 10px;
    font-size: 12px;
    color: rgb(140, 140, 142);
    width: 6%;
    height: 50px;
    text-align: center;
}

.MainBar .form-section .form-box .form-group.textarea label {
    height: 150px;
}

.MainBar .form-section .form-box .form-group input {
    background: #24242f;
    border-radius: 1px;
    border: 1px solid transparent;
    font-size: 12px;
    height: 50px;
    padding: 5px 10px;
    color: rgb(140, 140, 142);
}

.MainBar .form-section .form-box .form-group textarea {
    background: #24242f;
    border-radius: 1px;
    border: 1px solid transparent;
    font-size: 12px;
    height: 150px;
    padding: 20px 10px 10px;
    color: rgb(140, 140, 142);
}

.MainBar .form-section .form-box .form-group input:focus {
    outline: none;
    box-shadow: none;
}

.MainBar .form-section .form-box .form-group textarea:focus {
    outline: none;
    box-shadow: none;
}

.MainBar .form-section .form-box .btn:focus {
    outline: none;
    box-shadow: none;
}

.MainBar .form-section .form-box .btn {
    background: #ff6a06;
    border: 1px solid #ff6a06;
    border-radius: 1px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    padding: 13px 40px;
    letter-spacing: 1px;
}


.MainBar .footer-section {
    margin-top: 8%;
}

.MainBar .footer-section .footer-box {
    box-sizing: border-box;
    background: #2c2c38;
    height: 8vh;
}

.MainBar .footer-section .footer-box p {

    padding-top: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: rgb(140, 140, 142);
    letter-spacing: 0.5px;
}


.Sidebar {
    padding: 0px;
    height: 100%;
    border: 0px solid #cecece;
}

.Sidebar hr {
    border-top: 1px solid #424242;
}

.Sidebar .header-section {
    height: 35vh;
    background: #242430;
    padding: 25px 0;
    box-shadow: 0 1px 4px 0 rgba(15, 15, 20, 0.1);
}

.Sidebar .header-section .image-box {
    text-align: center;
}

.Sidebar .header-section .image-box .img-fluid {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 3px solid #ff6a06;
    object-fit: cover;
}

.Sidebar .Profile-info {
    margin-top: 10px;
    text-align: center;
}

.Sidebar .Profile-info h5 {
    font-size: 16px;
}

.Sidebar .Profile-info h3 {
    font-size: 14px;
}

.Sidebar .Profile-info p {
    font-size: 12px;
    color: #b6b6b6;
    font-weight: 300;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    letter-spacing: 1px;
}

.Sidebar .Profile-details {
    height: 60vh;
    padding: 20px;
    background: #20202a;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Sidebar .Profile-details::-webkit-scrollbar {
    display: none;
}

.Sidebar .Profile-details .profile-address {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
}

.Sidebar .Profile-details .profile-address h6 {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
}

.Sidebar .Profile-details .profile-address .address-two h6 {
    font-weight: 400;
    color: #b6b6b6;
}

.Sidebar .Profile-details .language {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
}

.Sidebar .Profile-details .language .language-status p {
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}

.Sidebar .Profile-details .skills ul {
    margin-top: 30px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.Sidebar .Profile-details .skills ul li {
    margin-bottom: 20px;
}

.Sidebar .Profile-details .skills .skills-text {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;

}

.Sidebar .Profile-details .skills .skills-text p {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 5px;
}

.skill-bar {
    background-color: #191923;
    width: 100%;
    border-radius: 15px;
}

.skill {
    background-color: #ff6a06;
    color: white;
    padding: 2px;
    text-align: right;
    border-radius: 15px;
}

.percent-100 {
    width: 100%;
}

.percent-90 {
    width: 90%;
}

.percent-80 {
    width: 80%;
}

.percent-70 {
    width: 70%;
}

.percent-60 {
    width: 60%;
}

.percent-50 {
    width: 50%;
}


.Sidebar .Profile-details .mini-skills ul {
    list-style: none;
    padding-left: 0;
}

.Sidebar .Profile-details .mini-skills .fas {
    color: #ff6a06;
}

.Sidebar .Profile-details .mini-skills ul li {
    font-size: 12px;
    color: #b6b6b6;
    letter-spacing: 1px;
    font-weight: 300;
}

.Sidebar .Profile-details .download-cv a {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #b6b6b6;
    text-decoration: none;
    text-transform: uppercase;
}


.Sidebar .side-footer {
    margin-top: 20px;
    background: #242430;
}

.Sidebar .side-footer .socials {
    margin: auto;
}

.Sidebar .side-footer .socials ul {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: 10px;
    margin: 0px 10px;
}

.Sidebar .side-footer .socials ul a {
    color: #b6b6b6;
    font-size: 15px;
}


@media (max-width: 1190px) {

    body {
        padding: 0px;
    }

    .Sidebar {
        display: none;
        width: 40%;
    }

    .Sidebar .header-section {
        height: 20vh;
        padding: 25px 0;
    }


    .Sidebar .Profile-details {
        height: 74vh;
        padding: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .Sidebar .side-footer .socials {
        padding-top: 10px;
        margin: auto;
    }


    .menu-section {
        width: 100%;
        display: block;
    }

    .menu-section .menu-box {
        box-sizing: border-box;
        background: #20202a;
        padding-top: 5px;
        padding-left: 20px;
        height: 60px;
    }

    .menu-section .menu-box .btn {
        background: #20202a;
        border: 1px solid #424242;
        font-size: 20px;
    }
    .menu-section .menu-box .btn:focus{
        box-shadow: none;
        outline: none;
        background: #20202a;
        border: 1px solid #424242;
    }
    .menu-section .menu-box .btn:active{
        box-shadow: none;
        outline: none;
        background: #20202a;
        border: 1px solid #424242;
    }

    .MainBar {
        padding: 0px 20px;
        height: 95vh;
    }

    .MainBar h4 {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
    }

    .MainBar .hero-section {
        text-align: center;
        margin-top: 3%;
        padding: 0px 50px;

    }

    .MainBar .hero-section .hero-text {
        padding: 50px 0 30px;
    }

    .MainBar .hero-section .hero-text h3 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .MainBar .hero-section .hero-text .code-section {
        margin-bottom: 20px;
        font-size: 17px;
    }

    .MainBar .hero-section .hero-text .hire-button .btn {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .MainBar .hero-section .hero-image {
        display: none;
    }

    .MainBar .work-experience {
        margin: 3% 0;
    }

    .MainBar .work-experience {
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-between;
    }

    .MainBar .work-experience .experience h5 {
        font-size: 20px;
        font-weight: 600;
    }

    .MainBar .work-experience .experience p {
        font-size: 13px;
        font-weight: 400;
    }

    .MainBar .service-section {
        margin-top: 5%;
    }

    .MainBar .service-section .service-box {
        padding: 20px 10px;
    }

    .MainBar .service-section .service-box h6 {
        font-size: 15px;
    }

    .MainBar .service-section .service-box p {
        font-size: 12px;
        line-height: 18px;
    }

    .MainBar .work-section {
        margin-top: 5%;
    }

    .MainBar .work-section .work-box.education {
        height: 180px;
    }

    .MainBar .work-section .work-box {
        padding: 30px 20px 20px;

    }

    .MainBar .work-section .work-box .work-header .work-title h5 {
        font-size: 14px;
    }

    .MainBar .work-section .work-box .work-header .work-title p {
        font-size: 12px;
    }

    .MainBar .work-section .work-box .work-header .work-time h6 {
        font-size: 10px;
        padding: 8px 15px;

    }

    .MainBar .work-section .work-box .work-description p {
        font-size: 12px;
    }


    .MainBar .contact-section {
        margin-top: 5%;
    }

    .MainBar .contact-section .contact-box {
        padding: 30px 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5%;

    }

    .MainBar .contact-section .contact-box p {
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 0.5px;
    }

    .MainBar .contact-section .contact-box .address-two p {
        font-weight: 300;
        text-transform: lowercase;
        text-align: right;
    }

    .MainBar .form-section {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .MainBar .form-section .form-box {
        padding: 30px;
        box-sizing: border-box;
        margin-bottom: 5%;

    }

    .MainBar .form-section .form-box .form-group {
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .MainBar .form-section .form-box .form-group label {
        padding: 10px;
        font-size: 12px;
        width: 6%;
        height: 50px;
        text-align: center;
    }

    .MainBar .form-section .form-box .form-group.textarea label {
        height: 150px;
    }

    .MainBar .form-section .form-box .form-group input {
        border-radius: 1px;
        border: 1px solid transparent;
        font-size: 12px;
        height: 50px;
        padding: 5px 10px;
    }

    .MainBar .form-section .form-box .form-group textarea {
        border-radius: 1px;
        border: 1px solid transparent;
        font-size: 12px;
        height: 150px;
        padding: 20px 10px 10px;
    }


    .MainBar .form-section .form-box .btn {
        border-radius: 1px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding: 13px 40px;
        letter-spacing: 1px;
    }


    .MainBar .footer-section {
        margin-top: 8%;

    }

    .MainBar .footer-section .footer-box {
        box-sizing: border-box;
        height: 8vh;
    }

    .MainBar .footer-section .footer-box p {

        padding-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.5px;
    }

}

@media (max-width: 900px) {

    .Sidebar {
        display: none;
        width: 50%;
    }
}



@media (max-width: 767px) {

    .Sidebar {
        display: none;
        width: 80%;
    }

    .MainBar .work-experience {
        margin: 3% 0;
    }


    .MainBar .work-experience {
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-between;
    }

    .MainBar .work-experience .experience h5 {
        font-size: 16px;
        font-weight: 600;
    }

    .MainBar .work-experience .experience p {
        font-size: 13px;
        font-weight: 400;
    }

}



@media (max-width: 540px) {

    .Sidebar {
        display: none;
        width: 80%;
        height: 92vh;
    }

    .Sidebar .header-section {
        height: 25vh;
        padding: 25px 0;
    }

    .Sidebar .Profile-details {
        height: 65vh;
        padding: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .Sidebar .side-footer {
        width: 100%;
        height: 7vh;
    }

    .Sidebar .side-footer .socials {
        padding-top: 10px;
        margin: auto;
    }


    .MainBar {
        padding: 0px 20px;
        height: 90vh;
    }

    .MainBar h4 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
    }

    .MainBar .hero-section {
        text-align: center;
        margin-top: 3%;
        padding: 0px 20px;

        /*background: #595960;*/

    }

    .MainBar .hero-section .hero-text {
        padding: 50px 0 30px;
    }

    .MainBar .hero-section .hero-text h3 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .MainBar .hero-section .hero-text .code-section {
        margin-bottom: 20px;
        font-size: 16px;
    }

    .MainBar .hero-section .hero-text .hire-button .btn {
        font-size: 11px;
        font-weight: 600;
        padding: 10px 30px;
        margin-bottom: 20px;
    }

    .MainBar .hero-section .hero-image {
        display: none;
    }

    .MainBar .work-experience {
        margin: 3% 0;
    }

    .MainBar .work-experience {
        display: flex;
        flex: auto;
        flex-direction: column;
        padding: 0 20px;
    }

    .MainBar .work-experience .experience {
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-between;
    }

    .MainBar .work-experience .experience h5 {
        font-size: 15px;
        font-weight: 600;
    }

    .MainBar .work-experience .experience p {
        font-size: 12px;
        font-weight: 400;
    }

    .MainBar .service-section {
        margin-top: 5%;
    }

    .MainBar .service-section .service-box {
        padding: 20px 10px;
    }

    .MainBar .service-section .service-box h6 {
        font-size: 13px;
    }

    .MainBar .service-section .service-box p {
        font-size: 11px;
        line-height: 18px;
    }

    .MainBar .work-section {
        margin-top: 5%;
    }

    .MainBar .work-section .work-box.education {
        height: 180px;
    }

    .MainBar .work-section .work-box {
        padding: 30px 10px 20px;
    }

    .MainBar .work-section .work-box .work-header .work-title h5 {
        font-size: 13px;
    }

    .MainBar .work-section .work-box .work-header .work-title p {
        font-size: 11px;
    }

    .MainBar .work-section .work-box .work-header .work-time h6 {
        font-size: 9px;
        padding: 6px 10px;

    }

    .MainBar .work-section .work-box .work-description p {
        font-size: 11px;
    }


    .MainBar .contact-section {
        margin-top: 5%;
    }

    .MainBar .form-section {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .MainBar .form-section .form-box {
        padding: 30px 10px;
        box-sizing: border-box;
        margin-bottom: 5%;

    }

    .MainBar .form-section .form-box .form-group {
        display: flex;
        flex: auto;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .MainBar .form-section .form-box .form-group label {
        padding: 10px;
        font-size: 12px;
        width: 12%;
        height: 45px;
        text-align: center;
    }

    .MainBar .form-section .form-box .form-group.textarea label {
        height: 120px;
    }

    .MainBar .form-section .form-box .form-group input {
        border-radius: 1px;
        border: 1px solid transparent;
        font-size: 12px;
        height: 45px;
        padding: 5px 10px;
    }

    .MainBar .form-section .form-box .form-group textarea {
        border-radius: 1px;
        border: 1px solid transparent;
        font-size: 12px;
        height: 120px;
        padding: 20px 10px 10px;
    }

    .MainBar .form-section .form-box .btn:focus {
        outline: none;
        box-shadow: none;
    }

    .MainBar .form-section .form-box .btn {
        font-size: 11px;
        padding: 10px 30px;
    }


    .MainBar .footer-section {
        margin-top: 15%;

    }

    .MainBar .footer-section .footer-box {
        box-sizing: border-box;
        height: 10vh;
    }

    .MainBar .footer-section .footer-box p {
        padding-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.5px;
        margin-bottom: 2px;
    }

}